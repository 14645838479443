<template>
  <footer class="bg-white py-16">
    <div class="max-w-7xl mx-auto px-8 flex justify-between items-center">
      <div>
        <p class="text-sm font-semibold text-slate-400">
          &copy; 2024 Streamfold Inc.
        </p>
      </div>
      <div class="flex gap-8">
        <NuxtLink
          to="/privacy/"
          class="text-sm font-semibold text-slate-400 hover:text-slate-600"
        >
          Privacy
        </NuxtLink>
        <NuxtLink
          to="/terms/"
          class="text-sm font-semibold text-slate-400 hover:text-slate-600"
        >
          Terms
        </NuxtLink>
      </div>
    </div>
  </footer>
</template>
